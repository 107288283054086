
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';  
import { HttpInterceptor } from '@angular/common/http'
 
@Injectable({
  providedIn: 'root'
})

export class AstrologyAppService
{
  base_url: any

  constructor(private http: HttpClient)
  {
 // this.base_url = 'http://localhost:4051/api/v1/'
    // this.base_url = 'http://10.0.2.2:3000/api/app/v1/'
  /*this.base_url = 'http://localhost:4050/api/v1/'*/
this.base_url = 'https://astrologers.predictforme.com:4050/api/v1/'
  }

  

 
  login(dataObject)
  {
    return this.http.post(this.base_url + "loginAstrologer", dataObject)
  }

  getAstrologyChartsUser(data)
  {
     //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
     return this.http.post(this.base_url + "getAstrologyChartsUser", data,
     )
  }

 getAstrologyChartsAdmin(data)
 {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    return this.http.post(this.base_url + "getAstrologyChartsUser1", data,
    )
 }
 getAstrologylaganChartsAdmin(data)
 {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    return this.http.post(this.base_url + "getAstrologylaganChartsUser", data,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
 }
  getAstrologyChartsAdminhouse(data)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    return this.http.post(this.base_url + "getAstrologyChartsUserhouse", data,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }
 getAstrologyHoraryChartsAdmin(data)
 {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    return this.http.post(this.base_url + "getAstrologyHoraryChartsUser", data,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
 }

 getAstrologyChartsUserHoraryhouse(data)
 {
  return this.http.post(this.base_url + "getAstrologyChartsUserHoraryhouse", data,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
 }
  getAllMembersForSelectedUserWebapp()
  {
    return this.http.get(this.base_url + "get_all_members_for_selected_user_webapp",
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }

  



savehoroscope(dataObjectVideo)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    var user = JSON.parse(localStorage.getItem("currentUser"));

    return this.http.post(this.base_url + "savehoroscope", dataObjectVideo,
    {
      headers: new HttpHeaders(
      {
         Authorization: user.token
      })
    })
  }
  getallcategory()
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    var user = JSON.parse(localStorage.getItem("currentUser"));
    console.log(user.token);
    return this.http.post(this.base_url + "getallcategory", 
    {
      headers: new HttpHeaders(
      {
         Authorization: user.token
      })
    })
  }

deletecategory(abc)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    var user = JSON.parse(localStorage.getItem("currentUser"));

    return this.http.post(this.base_url + "deletecategory", abc,
    {
      headers: new HttpHeaders(
      {
         Authorization: user.token
      })
    })
  }
  deletehoroscope(abc)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    var user = JSON.parse(localStorage.getItem("currentUser"));

    return this.http.post(this.base_url + "deletehoroscope", abc,
    {
      headers: new HttpHeaders(
      {
         Authorization: user.token
      })
    })
  }

getallhoroscope()
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
   // localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")

    var user = JSON.parse(localStorage.getItem("currentUser"));
    console.log(user);
    return this.http.post(this.base_url + "getallhoroscope", 
    {
      headers: new HttpHeaders(
      {
         Authorization: user.token
      })
    })
  }


  addnewcategory(dataObjectVideo)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    var user = JSON.parse(localStorage.getItem("currentUser"));

    return this.http.post(this.base_url + "addnewcategory", dataObjectVideo,
    {
      headers: new HttpHeaders(
      {
         Authorization: user.token
      })
    })
  }


  numerologyAlgorithmFunctionYear(dataObjectVideo)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
    return this.http.post(this.base_url + "numerologyAlgorithmFunctionYear", dataObjectVideo,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }

  numerologyAlgorithmFunctionMonth(dataObjectVideo)
  {
    //localStorage.setItem("token","JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MGY2MTUyM2NhOGNiYTBmMTEzNDNmMzMiLCJuYW1lIjoidGVzdGluZyIsImVtYWlsX2lkIjoidGVzdGluZ0B0ZXN0aW5nLmNvbSIsInBob25lTnVtYmVyIjoiMTIzNDU2Nzg5MCIsInBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwicmVFbnRlclBhc3N3b3JkIjoiJDJiJDEwJEZZTTIyMHN4WTh1MmZxcVJUeEdEWk85RmM5YmQubzFyRWJQbjdFMjZFTWczMDJpSHNRL3VTIiwiX192IjowfQ.O1-8Y82eAGVHUq3P3T1BcN0W--G6YA4a1qKt1SoGp8M")
   
    return this.http.post(this.base_url + "numerologyAlgorithmFunctionMonth", dataObjectVideo,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }

numerologyAlgorithmFunctionDate(dataObjectVideo)
  {
    return this.http.post(this.base_url + "numerologyAlgorithmFunctionDate", dataObjectVideo,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }



  getAllVAWArticlesForSelectedCategory(dataObjectArticle)
  {
    return this.http.post(this.base_url + "get_all_vaw_articles_for_selected_category_webapp", dataObjectArticle,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }

  addMuhuratRequestWebapp(dataObject)
  {
    return this.http.post(this.base_url + "get_muhurat_webapp", dataObject,
    // return this.http.post(this.base_url + "add_muhurat_request_webapp", dataObject,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    }) 
  }

  getAllQuestionsForSelectedEvent(dataObjectEvent)
  { 
    return this.http.post(this.base_url + "get_all_questions_for_selected_event_webapp", dataObjectEvent,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }

  getAnswerWebapp(dataObjectQuestion)
  { 
    return this.http.post(this.base_url + "get_answer_webapp", dataObjectQuestion,
    {
      headers: new HttpHeaders(
      {
         Authorization: localStorage.getItem("token")
      })
    })
  }

  // getLatLong(selectedItem)
  // {
  //   // Given By Sir | Google Places API Key
  //   return this.http.get('https://maps.googleapis.com/maps/api/place/details/json?placeid=' + selectedItem.place_id + '&key=AIzaSyBOjeuwl6O9UgKLCoMJNzSOvhha8Jw-RxQ');
  // }
  
  getToken()
  {
    return localStorage.getItem("token");
  }

  intercept(req, next)
  {
    let tokenizedReq = req.clone(
      {
        setHeaders:
        {
          Authorization: `Bearer ${this.getToken()}`
        }
      }
    )

    return next.handle(tokenizedReq)
  }
}

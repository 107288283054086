import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  currentNumber:undefined;
  currentOccurence:undefined;
  exp:any;
dict = [
  {type:"once",data:[
    {number:1,exp:"Confident,dominating,self regarding,proud,stubborn and they desire to be known or talked about,this number gives power and money"},
    {number:2,exp:"sensitive,disfunctional coping styles,seek emotional attention,these people are softspoken, emotional ,creative,attractive aura,good partners,they seek companionship in all activities"},
    {number:3,exp:"good planners,good decision makers,Hunger for knowledge,the only number which can say no to temptations,disciplined,good counsellors,they have their priorities sorted,clarity of what they want,life works on the principle of justice ,good understanding of right and wrong,good stress management skills,they walk their talk,family is priority,realistice bent of mind,good management skills"},
    {number:4,exp:"mood swings,sudden problems are faced,spend thrift,unstable relationships (family,friends,significant other,professional relations),travels (characterized with unexpected,sudden,fruitless,expensive ,travels are common to developing and gulf nations),unclear and continous change of goals,regular change in plan of action,poor planning skills,unrealstic goals in life,can't sit at home,can't always fullfill their commitments,unexpected health hazards or accidents,party lovers,have big social circle but can't maintain the circle, researchers specially IT professionals "},
    {number:5,exp:"calculative mind (in terms of money only),good speech,good negotiation skills (in terms of money),lack of emotions,have money making,highly routinised,straight forward"},
    {number:6,exp:"love for food,good aptitude for cooking,comfort is priority,have big friend circle (mostly opposite gender),love materialistic things (money,clothes,jewelry),drawn towards outer beauty ,particular about presentation,they are blunt speakers,creative (interior designers,models,painters,make up artists),attractive aura,brand cautious,loves outer beauty,foody,have an aptitude to cook good food"},
    {number:7,exp:"lucky people,have clear vision of their goals in life,planned and fruitful travels,good sense of reasoning/need logic to complete and endavour,stability"},
    {number:8,exp:"god believer,prone to depression,attractive aura,face struggle In all perspective of life,hard working,helpful nature,emphathatic,persistent,incur delays in results"},
    {number:9,exp:"aggressive,energetic,courageous,determined,like physical activities (eg sports,karate etc),bold,argumentative,short tempered,confident"},

    
  ]},
  {type:"twice",data:[
    {number:1,exp:"insecure nature,lack of confidence,easily intimidated,highly compliant,always needs an authority figure to direct them"},
    {number:2,exp:"emotionally dependent,highly sentimental (are majorly cry babies),soft spoken, emotional creatives"},
    {number:3,exp:"family problems ,attachment towards the family decreases ,hunger for knowledge"},
    {number:4,exp:"good planners,meaningful and fruitful travels,predictable,stable relations, negative qualities of single 4 reduces"},
    {number:5,exp:"prone to fraud or might incur fraud,disturbances in sleep"},
    {number:6,exp:"unstable relationships/fight in relationships (at work,family ,friends)"},
    {number:7,exp:"unstable life (place of living,marriage,career),have the habbit of getting under the skin,anxious nature (intensity increases as the 7 increases in DOB)"},
    {number:8,exp:"basic characteristics similar to 8 however the intensity of hardships decrease and they build assets in life,exponential increase in money ,person might become multi millionaire or has the capability to increase the money exponentially"},
    {number:9,exp:"frustated nature,display emotions in volcanic manner,usually lack courage"},

    
  ]},
  {type:"thrice",data:[
    {number:1,exp:"similar to double 1 however the intensity increases "},
    {number:2,exp:"similar to double 2 ,intensity increases "},
    {number:3,exp:"similar to double 3 ,intensity increases ,moral values decrease,family problems increase"},
    {number:4,exp:"illogical thought process,traits of single 4 however the intensity increases"},
    {number:5,exp:"similar traits to double 5 however the intensity increases"},
    {number:6,exp:"similar to double 6 ,however the intensity increases"},
    {number:7,exp:"similar to double 7 ,however the intensity increases"},
    {number:8,exp:"similar to single 8 ,hardships increase in life ,intensity of single 8 increases"},
    {number:9,exp:"frustated in nature,volcanic display of emotions(specifically anger),lack courage"},

    
  ]}
 
]
  constructor() { }

  ngOnInit() {}


  compareWith(o1, o2) {
    console.log(o1,o2)
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  handleChange(ev) {
    console.log(ev)
    this.currentNumber = ev.target.value;
    this.changeexp()
  }

  compareWith1(o1, o2) {
    console.log(o1,o2)
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  handleChange1(ev) {
    console.log(ev)
    this.currentOccurence = ev.target.value;
    this.changeexp()
  }

  changeexp()
  { if(this.currentNumber&&this.currentOccurence)
    {
    for(var i=0;i<this.dict.length;i++)
    {
      if(this.currentOccurence == this.dict[i].type)
      {
      for(var j=0;j<this.dict[i].data.length;j++)
      {
       if(this.currentNumber==this.dict[i].data[j].number)
       {
       this.exp = this.dict[i].data[j].exp
       }
      }
    }
    }
  }
  }
}
